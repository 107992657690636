import { render, staticRenderFns } from "./Tile.vue?vue&type=template&id=7c963a23"
import script from "./Tile.vue?vue&type=script&lang=ts"
export * from "./Tile.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports